import React from 'react';
import BottomNavigation from '@/components/nav/BottomNavigation'
import { TopNavigation } from '@/components/nav/TopNavigation';
import { montserrat } from '@/utils/fonts/fonts';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <>
      <div className='relative flex flex-col'>
        <div className='flex md:hidden fixed z-40 w-full bottom-0'>
          <header className='z-40 px-3 max-w-7xl mt-4 mx-auto mb-5 w-full'><TopNavigation/></header>
        </div>
        <header className='hidden md:block sticky md:top-8 bottom-5 z-40 px-3 max-w-7xl md:mt-8 mt-4 mx-auto md:mb-12 mb-5 w-full'><TopNavigation/></header>
        <main 
          className='min-h-screen w-full mx-auto px-5 mt-5 md:mt-0 mb-12 max-w-7xl' 
          style={montserrat.style}>
            {children}
        </main>
        <footer><BottomNavigation/></footer>
      </div>
    </>
  )
}
