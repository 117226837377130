import { ibm, montserrat } from "@/utils/fonts/fonts"
import Image from "next/image"
import Link from "next/link"

export default function BottomNavigation(){
    const pagesData = [
        {
            title: 'Главная',
            link: '/'
        }, {
            title: 'Музыка со стримов',
            link: '/streamed-music'
        }, {
            title: 'Игровые рецензии',
            link: '/game-reviews'
        }, { 
            title: 'Рейтинг',
            link: '/leaderboard'
        }, { 
            title: 'Логово',
            link: '/live'
        }, 
    ]

    const linksData = [
        {
            title: 'Основной канал',
            link: 'https://www.youtube.com/@TijoeVideos',
        }, {
            title: 'Записи стримов',
            link: 'https://www.youtube.com/@Tijoe',
        }, {
            title: 'Канал Мини-тиджа',
            link: 'https://www.youtube.com/@tijoe_small',
        }, {
            title: 'ВКонтакте',
            link: 'https://vk.com/tijoevideos',
        }, {
            title: 'Boosty',
            link: 'https://boosty.to/tijoe',
        }, {
            title: 'Twitch',
            link: 'https://www.twitch.tv/tijoe',
        }, {
            title: 'Telegram',
            link: 'https://t.me/tijoe',
        }, {
            title: 'Уведомления о стримах',
            link: 'https://t.me/tijoestream',
        }, {
            title: 'Last.fm',
            link: 'https://www.last.fm/ru/user/TijoeMusic',
        }
    ]

    return (
        <footer style={montserrat.style} className="border-t border-zinc-200/10 bg-[#151515]/50 relative w-full pb-32 pt-10 md:pb-8 text-white text-xs justify-center flex flex-col overflow-hidden px-5">
            <article className="w-full max-w-7xl px-3 mx-auto gap-8 md:gap-2 justify-between flex flex-col md:flex-row">
                <section className="flex flex-col gap-8 md:gap-1 justify-between">
                    <Image
                        src='/tijoe-avatar.png'
                        width={72}
                        height={72}
                        priority={true}
                        alt='Аватар Тиджоя'
                        draggable='false'
                        className="max-w-full h-auto rounded-xl"
                    />

                    <section className="hidden md:flex flex-col gap-1">
                        <p className="text-sm md:text-xs">
                            Для коммерческих запросов:&nbsp;
                            <a
                                href="mailto:commerce@tijoe.net"
                                className="underline underline-offset-4 hover:text-amber-400 hover:decoration-amber-500"
                                style={ibm.style}>
                                commerce@tijoe.net
                            </a>
                        </p>
                        <p className="text-zinc-500 ">Created with ❤ by <Link target="_blank" className=" hover:text-amber-400" href={'https://www.anivire.xyz/'}>anivire</Link></p>
                    </section>
                </section>

                <section className="flex flex-col gap-2">
                    <p className="font-medium text-base">Страницы</p>
                    <div style={ibm.style} className="grid grid-cols-1 gap-1">
                        {pagesData.map((item, index) => (
                            <Link 
                                key={index} 
                                href={item.link} 
                                className="text-zinc-400 text-sm hover:text-amber-400 w-fit">{item.title}</Link>
                        ))}
                    </div>
                </section>

                <section className="flex flex-col gap-2">
                    <p className="font-medium text-base">Ссылки</p>
                    <div style={ibm.style} className="grid grid-cols-2 gap-1">
                        {linksData.map((item, index) => (
                            <Link 
                                key={index} 
                                href={item.link} 
                                className="group text-zinc-400 text-sm hover:text-amber-400 pr-3 w-fit"
                                target="_blank">{item.title}</Link>
                        ))}
                    </div>
                </section>

                <section className="md:hidden flex flex-col gap-1 text-zinc-500">
                        <p className="text-xs">
                            Для коммерческих запросов:&nbsp;
                            <a
                                href="mailto:commerce@tijoe.net"
                                className="underline underline-offset-4 hover:text-amber-400 hover:decoration-amber-500"
                                style={ibm.style}>
                                commerce@tijoe.net
                            </a>
                        </p>
                        <p>Created with ❤ by <Link target="_blank" className=" hover:text-amber-400" href={'https://www.anivire.xyz/'}>anivire</Link></p>
                    </section>
            </article>
        </footer>
    )
}