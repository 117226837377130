"use client"

import classNames from "classnames";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC } from "react";
import RiHome2Fill from '~icons/ri/home-2-fill'
import RiMusic2Fill from '~icons/ri/music-2-fill'
import RiGamepadFill from '~icons/ri/gamepad-fill'
import RiTrophyFill from '~icons/ri/trophy-fill'
import RiArrowRightLine from '~icons/ri/arrow-right-line'
import RiLoader5Fill from '~icons/ri/loader-5-fill'
import useSWR from "swr";
import { ibm } from "@/utils/fonts/fonts";

interface Props {
    className?: string
}

export const TopNavigation: FC<Props> = (props) => {
    const pathname = usePathname();

    const { data, error } = useSWR<boolean>(
      ['https://api.tijoe.net/leaderboards/onlinestatus'],
      async (input: RequestInfo, init: RequestInit) => {
        const res = await fetch(input, init);
        return res.json();
      }, { refreshInterval: 60 * 1000 });

    return (
        <section style={ibm.style} className={classNames('h-16 relative mx-auto flex flex-row justify-between py-3 items-center w-full overflow-hidden rounded-full max-w-7xl text-sm gap-3  bg-[#151515]/80 p-4 backdrop-blur-3xl backdrop-brightness-150',
          props.className)}>
          <nav className="hidden md:flex flex-row items-center gap-3">
              <Link
                href="/"
                className={classNames('p-2 px-3 rounded-full hover:bg-zinc-800 transition-all duration-200 ease-in-out',
                  {'bg-zinc-800 ': pathname === '/',
                  '': pathname !== '/'})}>
                Главная
              </Link>

              <Link
                href="/streamed-music"
                className={classNames('p-2 px-5 rounded-full hover:bg-zinc-800 transition-all duration-200 ease-in-out',
                  {'bg-zinc-800': pathname === '/streamed-music',
                  '': pathname !== '/streamed-music'})}>
                Музыка со стримов
              </Link>

              <Link
                href="/game-reviews"
                className={classNames('p-2 px-5 rounded-full hover:bg-zinc-800 transition-all duration-200 ease-in-out',
                  {'bg-zinc-800': pathname.includes('/game-reviews'),
                  '': !pathname.includes('/game-reviews')})}>
                Игровые рецензии
              </Link>

              <Link
                href="/leaderboard"
                className={classNames('p-2 px-5 rounded-full hover:bg-zinc-800 transition-all duration-200 ease-in-out',
                  {'bg-zinc-800': pathname === '/leaderboard',
                    '': pathname !== '/leaderboard'})}>
                Рейтинг
              </Link>

              <Link
                href="/live"
                className={classNames('p-2 px-5 rounded-full hover:bg-zinc-800 transition-all duration-200 ease-in-out')}>
                Логово
              </Link>
          </nav>

          <nav className='md:hidden flex flex-row items-center justify justify-between w-full mx-4 text-lg'>
            <Link 
              href="/"
              className={classNames('flex flex-col items-center',
                  {'': pathname === '/',
                  'opacity-50': pathname !== '/'})}>
              <RiHome2Fill/>
              <p className="text-xs">Главная</p>
            </Link>
            
            <Link 
              href="/streamed-music"
              className={classNames('flex flex-col items-center',
                  {'': pathname === '/streamed-music',
                  'opacity-50': pathname !== '/streamed-music'})}>
              <RiMusic2Fill/>
              <p className="text-xs">Музыка</p>
            </Link>

            <Link
              href="/game-reviews" 
              className={classNames('flex flex-col items-center',
                  {'': pathname.includes('/game-reviews'),
                  'opacity-50': !pathname.includes('/game-reviews')})}>
              <RiGamepadFill/>
              <p className="text-xs">Игры</p>
            </Link>
           
            <Link
              href="/leaderboard" 
              className={classNames('flex flex-col items-center',
                  {'': pathname === '/leaderboard',
                  'opacity-50': pathname !== '/leaderboard'})}>
              <RiTrophyFill/>
              <p className="text-xs">Рейтинг</p>
            </Link>
          </nav>

          <section className="md:flex hidden">
            { data === undefined
              ? <div className="py-2 mx-6"><RiLoader5Fill className='animate-spin text-lg'/></div>
              : <Link
                href={data === true ? "https://www.twitch.tv/tijoe" : "https://t.me/tijoestream"}
                target='_blank'
                className={classNames('group px-3 hover:bg-zinc-800 py-2 rounded-full transition-all duration-200 ease-out flex flex-row items-center gap-3 overflow-hidden')}>
                <div className='flex flex-row items-center gap-2 translate-x-3.5 group-hover:-translate-x-6 transition-all duration-200 ease-in-out'>
                  {data === true 
                    ? <div className='w-5 h-5 bg-emerald-600/20 animate-pulse rounded-full shadow-emerald-500 shadow-2xl flex items-center justify-center group-hover:invisible transition-all duration-200 ease-in-out'>
                      <div className='w-2 h-2 bg-emerald-600 animate-pulse rounded-full shadow-emerald-500'/>
                    </div>
                    : <div className='w-5 h-5 bg-rose-600/20 animate-pulse rounded-full shadow-rose-500 shadow-2xl flex items-center justify-center group-hover:invisible transition-all duration-200 ease-in-out'>
                      <div className='w-2 h-2 bg-rose-600 animate-pulse rounded-full shadow-rose-500'/>
                    </div>
                  }
                  <span>{data === true ? 'стрим онлайн' : 'стрим оффлайн'}</span>
                </div>
                <RiArrowRightLine className='text-sm translate-x-10 group-hover:translate-x-0 transition-all duration-200 ease-in-out mr-1'/>
              </Link>
            }
          </section>
        </section>
    );
}